<template>
  <div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn0 btn-secondary">
          Actualiser
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <label for="">Nom</label>
        <input type="text" v-model="user_role.fullName" class="form-control" />
      </div>

      <div class="col-9">
        <label for="">Description</label>
        <input
          type="text"
          v-model="user_role.description"
          class="form-control"
        />
      </div>
    </div>
    <!-- {{ routes_access }} -->
    <!-- <ul>
      <li v-for="(item, index) in access" :key="index++">
        {{ item.title }}
        <ul>
          <li v-for="(_item, _index) in item.routes" :key="_index++">
            <input
              type="checkbox"
              name="_item_title"
              id="_item_title"
              :value="_item.title"
              class="m-1"
              :checked="false"
              @click="addRole(_item.title)"
            />
            <label for="_item_title" class="m-1">
              {{ _item.title }}
            </label>
          </li>
        </ul>
      </li>
    </ul> -->
    <br />
    <div class="row justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(user_role)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      // user_role: {},
      // routes_access: "",
    };
  },
  computed: {
    ...mapGetters("access", {
      access: "getAll",
    }),
    ...mapGetters("user", {
      user_role: "getUserRole",
    }),
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("user/updateRole", data);
    },

    async refresh() {
      let reference = this.$route.params.reference;
      this.$store.dispatch("user/showRole", reference);
    },

    async addRole(value) {
      if (value != "") {
        if (this.routes_access.includes("___" + value)) {
          this.routes_access = this.routes_access.replace("___" + value, "");
          return;
        }

        if (this.routes_access.includes(value + "___")) {
          this.routes_access = this.routes_access.replace(value + "___", "");
          return;
        }

        if (this.routes_access.includes(value)) {
          this.routes_access = this.routes_access.replace(value, "");
          return;
        }

        if (this.routes_access == "") {
          this.routes_access = value;
          return;
        }

        this.routes_access = this.routes_access + "___" + value;
        this.routes_access = this.routes_access.trim();
        return;
      }
    },
  },
  beforeMount() {
    let reference = this.$route.params.reference;
    this.$store.dispatch("user/showRole", reference);
  },
};
</script>
